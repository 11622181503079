import ReactGA from 'react-ga';

import { RESET, SET_PROFILE_UID, SET_PROFILE } from './actions';

const populationFromRoles = roles =>
  (roles || [])
    .map(r => r.toLowerCase())
    .sort((a, b) => a.localeCompare(b))
    .toString();

export function resetProfile() {
  ReactGA.set({ dimension2: '' });
  return {
    type: RESET,
  };
}

export function setProfileUid(uid) {
  return {
    type: SET_PROFILE_UID,
    uid,
  };
}

export function setProfile({ uid, ...rest }) {
  ReactGA.set({ dimension2: populationFromRoles(rest.roles) });
  return {
    type: SET_PROFILE,
    profile: { uid: String(uid), ...rest },
  };
}
