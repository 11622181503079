import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { injectIntl, intlShape } from 'react-intl';

import handleMobileDevices from '../lib/mobile/HandleMobileHOC';

import Theme from '../lib/Theme';

import { isSiteNotYetOpen, isSiteOpen } from '../selectors/siteDates';
import { getDefaultTab } from '../selectors/menu';

import {
  setCalendar,
  getMetrics,
  initializeFromUid,
  setIsLoading,
  reset,
} from '../redux/root/actionCreators';
import { resetProfile } from '../redux/profile/actionCreators';

import { application as applicationMessages } from '../i18n/dynamic';

// AppSkeleton provides... well the app skeleton (header, menu, ...)
import AppSkeleton from './AppSkeleton';

// Those are the different base components for the router
import Landing from './Landing';
import Contact from './Pages/Contact';
import Legal from './Pages/Legal';
import Invite from './Pages/Invite';
import Admin from './Pages/Admin';
import Calendar from './Pages/Calendar';
import Mentions from './Pages/Mentions';
import Splash from '../lib/Splash';
import Decline from './Pages/Decline';

import SiteNotOpened from './SiteNotOpened';

import CONFIG from '../config/config.yaml';
import CUSTOMIZATION from '../config/customization.yaml';
import CALENDAR from '../config/calendar.json';

const splash = !_.isUndefined(CUSTOMIZATION.splash)
  ? {
      backgroundColor: _.get(
        CUSTOMIZATION,
        'splash.backgroundColor',
        undefined,
      ),
      arrowColor: _.get(CUSTOMIZATION, 'splash.arrowColor', undefined),
    }
  : false;

/**
 * That's the root of the application
 *  - Application skeleton
 *  - All routes
 * Also handles:
 *  - Theme
 *  - isMobile flag
 * And finally:
 *  - Calendar setup
 *  - Login
 * @class  App
 * @author Sylvain Pont
 */
class App extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    history: PropTypes.shape().isRequired,
    // REDUX
    siteIsNotYetOpen: PropTypes.bool.isRequired,
    siteIsOpen: PropTypes.bool.isRequired,
    defaultTab: PropTypes.shape({ href: PropTypes.string }).isRequired,
  };

  constructor(props) {
    super(props);

    this.backgroundStyle = _.get(CUSTOMIZATION, 'style.backgroundStyle', {});
    this.fontFamily = Theme.getFontFamily();
  }

  /**
   * Initialization of the application
   *   - Set the calendar, if any
   *   - Login and request data
   */
  componentDidMount() {
    const { intl, dispatch, history } = this.props;
    // Give the site name to the browser
    document.title = CONFIG.name;

    try {
      window.localStorage.setItem('localStorageWorking', 'true');
      window.localStorage.removeItem('localStorageWorking');
    } catch (e) {
      // eslint-disable-next-line no-alert
      alert(
        `${intl.formatMessage(
          applicationMessages.localStorageNeeded,
        )}\n\n${intl.formatMessage(applicationMessages.updateBrowser)}`,
      );
    }

    // Initialize application Calendar (always, can't check yet if calendar is needed)
    // FIXME initialize on first use
    // => Saves in store { calendar, eventList }
    dispatch(setCalendar(CALENDAR));

    // Initialize application Metrics: Get quotas and values (nb attendees) (from GSheet Metrics)
    // => Saves in store { metrics }
    dispatch(getMetrics());

    // For admins for identity spoofing: when adding ?uid=<uid> to the URL (as in #/path/?uid=<uid>)
    history.listen((location, action) => {
      if (action === 'POP') {
        // POP = user ? REPLACE / PUSH = app.. (?)
        if (typeof URLSearchParams === 'undefined') return; // IE11 escape
        const query = new URLSearchParams(location.search); // Note: does not work on IE11
        const uid = query.get('uid');
        if (typeof uid === 'string') {
          dispatch(setIsLoading(true));
          dispatch(resetProfile());
          dispatch(reset());
          dispatch(initializeFromUid(uid));
          history.replace({ ...location, search: '' });
        }
      }
    });
  }

  /**
   * 1) Handle app font + background style (for non-MUI elements)
   * 2) Instanciate application skeleton for all pages
   * 3) Route URLs
   * @author Sylvain Pont
   */
  render() {
    const { siteIsNotYetOpen, defaultTab } = this.props;
    if (siteIsNotYetOpen) {
      return (
        <div
          style={{
            fontFamily: this.fontFamily,
            ...this.backgroundStyle,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {splash && <Splash {...splash} />}
          <div>
            <SiteNotOpened />
          </div>
        </div>
      );
    }

    return (
      <div>
        {splash && <Splash {...splash} />}
        <div
          className="app"
          style={{ fontFamily: this.fontFamily, ...this.backgroundStyle }}
        >
          <AppSkeleton>
            <Switch>
              <Route
                exact
                path="/"
                render={() => <Redirect to={defaultTab.href} />}
              />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/mentions" component={Mentions} />
              <Route exact path="/admin" component={Admin} />
              <Route exact path="/invite" component={Invite} />
              <Route exact path="/calendar/ical" component={Calendar} />
              <Route exact path="/legal" component={Legal} />
              <Route exact path="/decline" component={Decline} />
              <Route path="/:tab?/:modify?" component={Landing} />
            </Switch>
          </AppSkeleton>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ profile }) => ({
  siteIsNotYetOpen: isSiteNotYetOpen(),
  siteIsOpen: isSiteOpen(),
  defaultTab: getDefaultTab({ profile }),
});

export default _.flowRight(
  connect(mapStateToProps),
  injectIntl,
  handleMobileDevices,
)(App);
