import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Link } from 'react-router-dom';

import { FormattedMessage } from 'react-intl';

import { loginLogin, loginLogout } from '../redux/root/actionCreators';

import MagicLink from './Login/LoginMagicLink';

import CONFIG from '../config/config.yaml';

class Login extends React.Component {
  static propTypes = {
    creds: PropTypes.objectOf(PropTypes.any),
    fetching: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    providers: PropTypes.arrayOf(PropTypes.string),
    loginHint: PropTypes.string,
    magic: PropTypes.bool.isRequired,
    location: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    creds: null,
    providers: ['resa'],
    loginHint: null,
  };

  constructor(props) {
    super(props);
    const { providers: prov, loginHint } = props;
    const providers = [...prov];
    if (loginHint && providers.indexOf(loginHint) === -1)
      providers.push(loginHint);
    this.state = {
      loginResa: providers.indexOf('resa') !== -1,
      loginSncf: providers.indexOf('sncf') !== -1,
      loginGoogle: providers.indexOf('goog') !== -1,
      loginPool: providers.indexOf('pool') !== -1,
      loginYammer: providers.indexOf('yamr') !== -1,
      loginMicrosoft: providers.indexOf('msft') !== -1,
      accept: false,
    };
    this.handleLoginGoogle = this.handleLogin.bind(this, 'goog');
    this.handleLoginPool = this.handleLogin.bind(this, 'pool');
    this.handleLoginYammer = this.handleLogin.bind(this, 'yamr');
    this.handleLoginSncf = this.handleLogin.bind(this, 'sncf');
    this.handleLoginMicrosoft = this.handleLogin.bind(this, 'msft');
    this.handleLogout = this.handleLogout.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleLogin(loginHint, evt) {
    const { dispatch } = this.props;
    const yamrAud =
      evt.shiftKey && evt.altKey
        ? 'RWWN8ElliNAFoZQvWK4j6w'
        : CONFIG.yammer.clientId;
    const params = loginHint === 'yamr' ? { aud: yamrAud } : undefined;
    return dispatch(loginLogin(loginHint, params));
  }

  handleLogout() {
    const { dispatch } = this.props;
    return dispatch(loginLogout());
  }

  handleToggle(event, checked) {
    this.setState({ accept: checked });
  }

  render() {
    const {
      creds,
      fetching,
      magic: magicLink,
      location: { pathname },
    } = this.props;

    const {
      loginResa,
      loginSncf,
      loginGoogle,
      loginPool,
      loginYammer,
      loginMicrosoft,
      accept,
    } = this.state;

    const disabled = fetching || !accept;

    // FIXME - Make it a component !
    const loginButton = (
      <div>

        <div className="login-buttons">
          {loginSncf ? (
            <button
              type="button"
              className={cx('login-button', 'sncf-login-button', {
                disabled,
              })}
              onClick={this.handleLoginSncf}
              onKeyPress={this.handleLoginSncf}
              disabled={disabled}
            >
              <span>AUTHENTIFICATION</span>
            </button>
          ) : null}
          {loginGoogle ? (
            <button
              type="button"
              className={cx('login-button', 'google-login-button', {
                disabled,
              })}
              onClick={this.handleLoginGoogle}
              onKeyPress={this.handleLoginGoogle}
              disabled={disabled}
            />
          ) : null}{' '}
          {loginPool ? (
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleLoginPool}
              disabled={disabled}
            >
              <FormattedMessage
                id="login.pool"
                defaultMessage="Login with Phileog"
              />
            </Button>
          ) : null}
          {loginYammer ? (
            <button
              type="button"
              className={cx('login-button', 'yammer-login-button', {
                disabled,
              })}
              onClick={this.handleLoginYammer}
              onKeyPress={this.handleLoginYammer}
              disabled={disabled}
            />
          ) : null}
          {loginMicrosoft ? (
            <button
              type="button"
              className={cx('login-button', 'msft-login-button', {
                disabled,
              })}
              onClick={this.handleLoginMicrosoft}
              onKeyPress={this.handleLoginMicrosoft}
              disabled={disabled}
            >
               <span>Sign in with Microsoft</span>
            </button>
          ) : null}
        </div>
        {loginResa ? (
          <div>

            <div className="login-buttons">
              <Button
                variant="contained"
                color="secondary"
                disabled={disabled}
                component={disabled ? 'div' : Link}
                to={disabled ? undefined : `${pathname}/magic`}
              >
                Suivant
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    );
    const logoutButton = (
      <div>
        <FormattedMessage
          id="profile.unauthentified"
          defaultMessage="We were unable to identify your profile."
        />
        <br />
        <FormattedMessage
          id="word.pleaselogout"
          defaultMessage="Please log out"
        />
        <button type="button" onClick={this.handleLogout}>
          <FormattedMessage id="word.logout" defaultMessage="Logout" />
        </button>
      </div>
    );
    const login = (
      <div style={{ fontSize: '16px' }}>
        <p>
          L{"'"}utilisateur accepte de fournir des données à caractère
          personnel, notamment ses nom et prénom, son adresse e-mail.{' '}
          <Link to="/legal">Consultez les mentions légales</Link>.
        </p>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <p>
            J&apos;accepte les conditions portant sur la collecte des données
            personnelles décrites ci-dessus.
          </p>

          <FormControlLabel
            control={<Switch onChange={this.handleToggle} checked={accept} />}
            label="J'accepte"
          />

          <br />
        </div>
        {magicLink ? <MagicLink accept={accept} /> : loginButton}
      </div>
    );
    return (
      <div>
        <h1>
          Identifiez vous
         </h1>
        {!creds ? login : logoutButton}
        <br />
        <br />
        <br />
      </div>
    );
  }
}

const mapStateToProps = ({ login }) => ({
  ...login,
});

export default connect(mapStateToProps)(Login);
