import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import CONFIG from '../../config/config.yaml';

const { name, controller } = CONFIG;

const Legal = () => (
  <div className="tab">
    <Card>
      <CardContent>
        <h1>Mentions légales</h1>
        <h2>Clauses couvertes par ce document</h2>
        <p>
          La protection des données et le respect de la vie privée sont
          important pour {controller} et ses sous-traitants. Ce document couvre
          l{"'"}accès aux information personnelles nécessaires au fonctionnement
          de la plateforme d{"'"}inscription à : {name}. Notamment :
        </p>
        <ul>
          <li>Quelles informations sont collectées sur les utilisateurs</li>
          <li>Comment et avec qui ces informations sont utilisées</li>
          <li>Comment elles sont conservées et sécurisées</li>
          <li>
            Comment exercer vos droits en tant qu{"'"}utilisateur de la
            plateforme
          </li>
        </ul>
        <p>
          L{"'"}ensemble des données transmises sont soumises aux dispositions
          de loi n° 78-17 du 6 janvier 1978, dite « informatique et libertés »
          et du nouveau règlement européen sur la protection des données
          personnelles en application le 25 mai 2018.
          <strong>
            En cas de désaccord avec les termes de ce document, veuillez cesser
            d{"'"}intéragir avec nos services et n{"'"}accédez plus à cette
            page.
          </strong>
        </p>
        <h2>Quelles informations sont collectées</h2>
        <p>
          Les données personnelles collectées sont celles volontairement
          fournies par l’Utilisateur, notamment ses nom et prénom, et adresse
          email.
        </p>
        <p>
          {controller} ne collecte aucune donnée personnelle sur l’Utilisateur,
          si ce n’est celles que l’Utilisateur aura volontairement enregistrées.
        </p>
        <p>
          Certaines informations demandées, repérées par un astérisque, sont
          nécessaires au traitement des requêtes des Utilisateurs. A défaut de
          réponse de la part de l’Utilisateur aux informations demandées et
          obligatoires, {controller} ne pourra lui adresser les informations
          sollicitées.
        </p>
        <p>
          A des fins de qualité de service, des informations techniques peuvent
          être collectées, comme par exemple l{"'"}adresse IP, le type de
          navigateur ou le système d{"'"}exploitation. Elles ne sont pas
          conservées dans le temps et ne servent le cas échéant qu{"'"}au
          maintient du bon fonctionnement de la plateforme. Elles peuvent
          néamoins avoir vocation à permettre diverses mesures de fréquentation,
          de manière anonyme.
        </p>
        <p>
          La navigation sur le Site est susceptible de provoquer l{"'"}
          installation de cookie(s) sur l{"'"}ordinateur de l{"'"}Utilisateur.
          Si l{"'"}Utilisateur s{"'"}oppose à l{"'"}installation des cookies ou
          procède à leur suppression, l{"'"}utilisation du Site pourrait se
          révéler altérée, voire impossible.
        </p>
        <h2>Comment les informations sont utilisées</h2>
        <p>
          Le responsable du traitement de ces données est {controller},
          organisateur de {name}. La finalité du Site est d{"'"}enregistrer les
          participants à : {name}.
        </p>
        <p>
          Les données collectées sur le site sont destinées uniquement au bon
          déroulé de l{"'"}opération. et exclusivement partagées avec le
          personnel concerné des équipes organisatrice de : {name}.
        </p>
        <p>
          {controller} respecte la vie privée des Utilisateurs, et l’ensemble
          des données personnelles que l’Utilisateur serait amené à transmettre
          au Site, sont soumises aux dispositions de loi n° 78-17 du 6 janvier
          1978, dite « informatique et libertés » et du nouveau règlement
          européen sur la protection des données personnelles en application
          depuis le 25 mai 2018. Ces informations ne pourront en aucun cas être
          utilisées à des fins commerciales.
        </p>
        <h2>Comment les informations sont conservées</h2>
        <p>
          {controller} et ses sous-traitants utilisent des fournisseurs de
          services hébergés, basés en France et en Europe. Ceux-ci garantissent
          la sécurisation de leurs datacenter et l{"'"}encryption des données au
          repos.
        </p>
        <p>
          Si un problème de sécurité entrainant une fuite de données devait être
          porté à notre connaissance, {controller} s{"'"}engage à en avertir les
          personnes concernées de manière appropriée.
        </p>
        <p>
          Les données à caractère personnel ne seront conservées au maximum que
          3 mois après la date de l{"'"}événement: {name}.
        </p>
        <h2>Comment exercer vos droits</h2>
        <p>
          Pour toute question relative au fonctionnement du service
          d’hébergement des données des Utilisateurs il est possible de
          s’adresser à :
        </p>
        <p>
          {controller} <br />
          PHILEOG ELEVEN <br />
          34 rue Camille Pelletan <br />
          92120 Montrouge <br />
        </p>
        <p>
          L’Utilisateur est informé qu’il dispose d’un droit d’accès,
          d’opposition, de rectification et de suppression de ses données
          conformément à la loi n° 78-17 du 6 janvier 1978, dite « informatique
          et libertés » et au nouveau règlement européen sur la protection des
          données personnelles en application depuis le 25 mai 2018.
        </p>
        <p>
          A ce titre, il peut exiger que soient rectifiées, complétées,
          clarifiées, mises à jour ou supprimées les informations le concernant
          qui sont inexactes, incomplètes, équivoques, périmées ou dont la
          collecte ou l{"'"}utilisation, la communication ou la conservation est
          interdite.
        </p>
        <p>
          Chaque Utilisateur peut exercer ce droit à tout moment en envoyant un
          courrier postal à l’adresse suivante :
        </p>
        <p>
          {controller} <br />
          PHILEOG ELEVEN <br />
          34 rue Camille Pelletan <br />
          92120 Montrouge <br />
        </p>
        <p>
          {controller} et ses sous-traitants feront leur possible pour effectuer
          au plus vite les changements demandés.
        </p>
      </CardContent>
    </Card>
  </div>
);

export default Legal;
