import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Markdown from '../../lib/SimpleContent/Markdown';

const Information = () => (
  <Card>
    <CardContent>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <div className="box">
            <Markdown mdKey="information" />
          </div>
        </div>
        <div className="col-xs-12 col-sm-6">
          <div className="box">
            <img
              style={{ maxWidth: '100%' }}
              src="infos_pratiques/plan.jpg"
              alt="Carte"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div
          className="col-xs-12" >
           <div className="box">
            <Markdown mdKey="faq" />
          </div>
        </div>
      </div>

    </CardContent>
  </Card>
);

export default Information;
