import React from 'react';
import PropTypes from 'prop-types';

import SummonField from './SummonField';

import { requiredValidator } from '../validators';

const Time = props => {
  const { name, disabled, field } = props;
  const { label, required, ...input } = field;

  const validatorsArray = [];
  if (required) validatorsArray.push(requiredValidator);

  return (
    <SummonField
      key={`summonField-${name}`}
      reduxFormField={{
        name,
        validate: [...validatorsArray],
      }}
      component="time"
      componentProps={{
        label: label && (
          <span>
            {label}
            {required && (
              <span style={{ color: 'red', opacity: '0.87' }}>&nbsp;*</span>
            )}
          </span>
        ),
        disabled,
        fullWidth: true,
        disableOpenOnEnter: true,
        ...input,
      }}
    />
  );
};

Time.prototype.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  field: PropTypes.shape().isRequired,
};

export default Time;
