import Welcome from './Pages/Welcome';
import Information from './Pages/Information';
import MainForm from './Form/MainForm';
import InscriptionsClosed from './Form/InscriptionsClosed';
import Invite from './Pages/Invite';
import Admin from './Pages/Admin';
import Video from './Pages/Video';
import DataPrivacy from './Pages/DataPrivacy';
import Faq from './Pages/Faq';
import Closed from './Pages/Closed';
import Mentions from './Pages/Mentions';
import Intervenants from './Pages/Intervenants';

export default {
  Welcome,
  Information,
  MainForm,
  InscriptionsClosed,
  Invite,
  Faq,
  Closed,
  Admin,
  Video,
  DataPrivacy,
  Mentions,
  Intervenants,
};
