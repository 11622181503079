import React, { Component } from 'react';
// import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Markdown from '../../lib/SimpleContent/Markdown';

import Carousel, { generateCarouselImages } from '../../lib/Carousel';

import photos from '../../config/carousel.yaml';

const photoUrlPrefix =
  'https://d2bz2v6wegwutd.cloudfront.net/static/sncf/1906-risques-routiers/img';

class Mentions extends Component {
  static propTypes = {
    // isActive: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.photos = {
      rr: generateCarouselImages(photos.rr, photoUrlPrefix),
      challenge: generateCarouselImages(photos.challenge, photoUrlPrefix),
    };
  }

  render() {
    // const { isActive } = this.props;
    return (
      <div className="tab">
        <Card className="ui-card">
          <CardContent>
            <Markdown mdKey="mentions" />
            <h3>Photos Risque Routier</h3>
            <Carousel
              images={this.photos.rr}
              maxHeight="300px"
              sideOverlap="15%"
              // isActive={isActive}
            />
              <h3>Photos Challenge Risque Routier</h3>
            <Carousel
              images={this.photos.challenge}
              maxHeight="300px"
              sideOverlap="15%"
              // isActive={isActive}
            />
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default Mentions;
