import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import SHA1 from 'crypto-js/sha1';

import Utils from '../../Utils';

import FileManager from './FileUpload/FileManager';
import FileUploadButton from './FileUpload/FileUploadButton';
import FileDropZone from './FileUpload/FileDropZone';

class FileUpload extends Component {
  static propTypes = {
    assetsBaseUrl: PropTypes.string,
    filesBaseUrl: PropTypes.string,
    uid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    field: PropTypes.shape().isRequired,
    value: PropTypes.arrayOf(PropTypes.shape()),
    uploadFile: PropTypes.func.isRequired,
    registerField: PropTypes.func,
    changeFieldValue: PropTypes.func,
    blockForm: PropTypes.func,
    releaseForm: PropTypes.func,
  };

  static defaultProps = {
    assetsBaseUrl: '',
    filesBaseUrl: '',
    disabled: false,
    value: undefined,
    registerField: _.noop,
    changeFieldValue: _.noop,
    blockForm: _.noop,
    releaseForm: _.noop,
  };

  constructor(props) {
    super(props);

    this.abortCallbacks = {};
    this.state = { files: [], filesProgress: {} };

    if (_.isNil(props.value)) {
      props.registerField(props.name);
    } else {
      const files = this.getFilesFromProps(props.value);
      if (files) {
        this.state.files = files;
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const files = this.getFilesFromProps(nextProps.value);
    if (files) {
      this.setState({ files });
    }
  }

  onAddFile = file => {
    const reader = new FileReader();
    reader.onload = () => this.onFileLoad(file, reader.result);
    reader.readAsDataURL(file);
  };

  onFileLoad = (file, fileData) => {
    const {
      uid,
      field: { multiple = false },
      changeFieldValue,
      name,
    } = this.props;
    const { files } = this.state;

    const ext = Utils.getExtFromFileInfos(file.type, file.name);

    const hash = SHA1(`${uid}-${file.name}-${file.size}`).toString();

    // Do not re-add already there files
    if (!_.find(files, f => f.meta.id === hash)) {
      const newFile = {
        // Saved in DB (GraphQL Mutation)
        savedMeta: {
          id: hash,
          name: file.name,
          type: file.type,
          size: file.size,
          mtime: file.lastModified,
        },
        meta: {
          id: hash,
          name: file.name,
          ext,
          size: file.size,
          mtime: file.lastModified,
          uid,
        },
        file,
        fileData,
      };
      this.uploadFile(newFile);
      let newFiles = [newFile];
      if (multiple) {
        newFiles = [...files, ...newFiles];
      }
      this.setState({
        files: newFiles,
      });
      changeFieldValue(name, newFiles.map(f => f.savedMeta));
    }
  };

  getFilesFromProps(files) {
    const { uid } = this.props;
    const { files: stateFiles } = this.state;
    if (_.isEmpty(stateFiles) && !_.isEmpty(files)) {
      return files.map(f => ({
        savedMeta: f,
        meta: {
          id: f.id,
          name: f.name,
          ext: Utils.getExtFromFileInfos(f.type, f.name),
          size: f.size,
          mtime: f.mtime,
          uid,
        },
      }));
    }
    return false;
  }

  uploadFile({ meta, file }) {
    const { blockForm, uploadFile } = this.props;
    const { filesProgress, files } = this.state;
    blockForm();
    this.setState({
      filesProgress: {
        ...filesProgress,
        [meta.id]: 0,
      },
    });
    const progressCallback = progress => {
      if (!_.isUndefined(filesProgress[meta.id])) {
        this.setState({
          filesProgress: {
            ...filesProgress,
            [meta.id]: Math.round((progress.loaded / progress.total) * 100),
          },
        });
      }
    };
    const abortCallbackSaver = abortCallback => {
      this.abortCallbacks[meta.id] = abortCallback;
    };
    uploadFile({ meta, file }, progressCallback, abortCallbackSaver)
      .then(_.noop)
      .catch(_.noop)
      .then(() => {
        // Always, as uploaded or error occured
        if (files.find(({ meta: fMeta }) => fMeta.id === meta.id)) {
          // If file is still there set upload as ended
          this.fileUploadEnded(meta.id);
        }
      });
  }

  fileUploadEnded(metaId) {
    const { releaseForm } = this.props;
    const { filesProgress } = this.state;
    // Delete the abortCallback
    delete this.abortCallbacks[metaId];
    // Remove the progress info
    const newFilesProgress = _.omit(filesProgress, metaId);
    if (_.size(newFilesProgress) === 0) {
      releaseForm();
    }
    this.setState({ filesProgress: newFilesProgress });
  }

  removeFile = metaId => {
    const { changeFieldValue, name } = this.props;
    const { files } = this.state;
    this.setState(
      () => ({
        files: files.filter(({ meta }) => meta.id !== metaId),
      }),
      () => {
        changeFieldValue(name, files.map(f => f.savedMeta));
        // Clean up file upload infos
        this.fileUploadEnded(metaId);
        // If there is an upload in progress, abort it
        if (this.abortCallbacks[metaId]) {
          this.abortCallbacks[metaId]();
        }
      },
    );
  };

  render() {
    const { name, disabled, field, filesBaseUrl, assetsBaseUrl } = this.props;
    const {
      label,
      onlyImage = false,
      capture = false,
      multiple = false,
    } = field;

    const { files, filesProgress } = this.state;

    const simpleStyle = {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    };
    const multipleStyle = {
      position: 'relative',
    };

    return (
      <div style={multiple ? multipleStyle : simpleStyle}>
        <FileManager
          name={name}
          files={files}
          filesProgress={filesProgress}
          handleRemove={this.removeFile}
          assetsBaseUrl={assetsBaseUrl}
          filesBaseUrl={filesBaseUrl}
          multiple={multiple}
          onlyImage={onlyImage}
        />
        <div>
          <FileUploadButton
            label={label}
            multiple={multiple}
            onlyImage={onlyImage}
            capture={capture}
            disabled={disabled}
            handleAddFile={this.onAddFile}
          />
          <FileDropZone handleAddFile={this.onAddFile} />
        </div>
      </div>
    );
  }
}

export default FileUpload;
