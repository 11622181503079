import AWS from 'aws-sdk';
import _ from 'lodash';
import { ResaCredentials } from 'phileog-login-client';

import CONFIG from '../config/config.yaml';

// Initialize the Amazon Cognito credentials provider
AWS.config.httpOptions.timeout = 0; // disable 2 mins timeout on slow networks
AWS.config.region = 'us-east-1'; // Region
// Anonymous credentials - for reference
// AWS.config.credentials = new AWS.CognitoIdentityCredentials({
//   IdentityPoolId: 'us-east-1:3bcca67a-035d-4d82-9a0c-3595fea850ef',
// });

const encodeRFC5987ValueChars = str =>
  encodeURIComponent(str)
    // Note that although RFC3986 reserves "!", RFC5987 does not, so we do not need to escape it
    .replace(/['()]/g, escape) // i.e., %27 %28 %29
    .replace(/\*/g, '%2A')
    // The following are not required for percent-encoding per RFC5987, so we can allow for a little better readability over the wire: |`^
    .replace(/%(?:7C|60|5E)/g, unescape);

class Aws {
  constructor() {
    this.lambdas = [];
    this.S3 = null;
    this.isReady = new Promise(resolve => {
      this.resolveReady = resolve;
    });
  }

  init(creds, dispatch) {
    const { IdentityPoolId = '' } = creds;
    const region = IdentityPoolId.split(':')[0] || 'us-east-1';
    AWS.config.credentials = new ResaCredentials(
      {
        ...creds,
        // RoleArn: 'arn:aws:iam::047863514376:role/Cognito_RESAAdminAuth_Role', // don't set or Cognito fails
        // see https://docs.aws.amazon.com/cognitoidentity/latest/APIReference/API_SetIdentityPoolRoles.html (type: 'Token')
      },
      { dispatch, region },
    );
    // TODO - AWS.config.credentials.get / refreshPromise / needsRefresh ?
    this.lambdas = [
      new AWS.Lambda({
        region: 'eu-west-1',
        apiVersion: '2015-03-31',
      }),
      new AWS.Lambda({
        region: 'us-east-1',
        apiVersion: '2015-03-31',
      }),
    ];
    this.resolveReady();
  }

  ready() {
    return this.isReady;
  }

  // http://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/Lambda.html#invoke-property
  invokeLambda(payload, retry = 0) {
    if (!this.lambdas.length) return Promise.reject(new Error('Aws not ready'));
    const lambda = this.lambdas[retry % this.lambdas.length];
    const params = {
      FunctionName: 'resaGraphQL',
      InvocationType: 'RequestResponse', // 'Event ?'
      // LogType: 'Tail',
      Payload: JSON.stringify(payload),
    };
    return new Promise((res, rej) => {
      lambda.invoke(params, (err, result) => {
        if (err) {
          rej(err);
          return;
        }
        if (result.FunctionError) {
          const e = new Error(result.FunctionError); // Handled or Unhandled
          e.payload = result.Payload;
          rej(e);
          return;
        }
        try {
          res(JSON.parse(result.Payload));
          return;
        } catch (e) {
          rej(e);
        }
      });
    });
  }

  uploadS3(file, meta) {
    const { resaKey, S3Bucket } = CONFIG;

    if (_.isNull(this.S3)) {
      this.S3 = new AWS.S3({
        apiVersion: '2006-03-01',
        region: 'eu-west-3', // FR
      });
    }

    const uid = meta.uid || 'anonymous';
    const mtime = file.lastModifiedDate && file.lastModifiedDate.toISOString();

    const metadata = { uid };
    if (mtime) metadata.mtime = mtime;

    return this.S3.upload(
      {
        Bucket: S3Bucket,
        Key: `resa/attachments/${resaKey}/${uid}/${meta.id}`,
        // StorageClass: 'REDUCED_REDUNDANCY', // keep by default for now
        Body: file,
        CacheControl: 'max-age=86400',
        ContentType: file.type,
        ContentDisposition: `attachment; filename*=UTF-8''${encodeRFC5987ValueChars(
          file.name,
        )}`,
        Metadata: metadata,
      },
      { partSize: 5 * 1024 * 1024, queueSize: 4 },
    );
  }
}

/**
 * Invoke lambda and if it fails try again, 3 times
 * @param  {object} payload
 * @param  {object} aws
 * @return {Promise}
 * @author Sylvain Pont
 */
const invokeLambda = (payload, aws) => {
  let retryCount = 0;
  const invoke = () =>
    aws.invokeLambda(payload, retryCount).then(
      response => {
        const {
          body: { errors },
        } = response;
        if (errors) {
          retryCount += 1;
          if (retryCount <= 3) {
            const errorMessage = `Lambda invoke error #${retryCount}, try again`;
            console.error(errorMessage, payload, errors);
            return invoke();
          }
          const errorMessage = `Lambda invoke error, give up`;
          console.error(errorMessage, payload, errors);
          const err = new Error('Lambda invoke error');
          err.payload = payload;
          throw err;
        }
        return response;
      },
      error => {
        if (error.code === 'CredentialsError') {
          // token is probably expired, don't retry
          throw error;
        }
        retryCount += 1;
        if (retryCount <= 3) {
          const errorMessage = `Lambda invoke error #${retryCount}, try again`;
          console.error(errorMessage, payload, error);
          return invoke();
        }
        const errorMessage = `Lambda invoke error, give up`;
        console.error(errorMessage, payload, error);
        throw error;
      },
    );
  return invoke;
};

export { invokeLambda };
export default Aws;
