import React from 'react';
import { render } from 'react-dom';
import { HashRouter, Route, Switch } from 'react-router-dom';
import Helmet from 'react-helmet';
import { Provider as ReduxProvider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/fr';

import store from './redux/store';
import Raven from './lib/Raven';
import GA, { GAListener } from './lib/GA';
import I18n from './lib/I18n';
import Theme from './lib/Theme';

// List of possible outcomes
import App from './components/App';
import GraphiQL from './components/GraphiQL';

import CONFIG from './config/config.yaml';
import CUSTOMIZATION from './config/customization.yaml';

import * as serviceWorker from './serviceWorker';

// Translations
I18n.init();
const locale = I18n.getLocale();
moment.locale(locale);

// Sentry
Raven.init();

// Google analytics
GA.init();

// Material-UI
const muiTheme = Theme.getTheme();

const Bootstrap = () => (
  <>
    <Helmet>
      <title>{CONFIG.name}</title>
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content={CUSTOMIZATION.style.palette.accent1Color}
      />
      <meta name="apple-mobile-web-app-title" content={CONFIG.name} />
      <meta property="og:title" content={CONFIG.name} />
      <meta
        property="og:url"
        content={`https://jasmin.resa-event.com/${
          CONFIG.resaKey
        }/index.html?login_hint=yamr#/`}
      />
      <meta
        property="og:image"
        content={`https://jasmin.resa-event.com/${
          CONFIG.resaKey
        }/img/preview.jpg`}
      />
      <meta property="og:description" content={CONFIG.description} />
    </Helmet>
    <ReduxProvider store={store}>
      <MuiThemeProvider theme={muiTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <IntlProvider
            defaultLocale="en"
            locale={locale}
            messages={I18n.translations[locale]}
          >
            <HashRouter>
              <GAListener>
                <Switch>
                  {process.env.NODE_ENV === 'development' ? (
                    <Route exact path="/graphiql" component={GraphiQL} />
                  ) : null}
                  {/* App is the second hand bootstrap */}
                  <Route path="/" component={App} />
                </Switch>
              </GAListener>
            </HashRouter>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </ReduxProvider>
  </>
);

const renderApp = () => {
  window.animationWaited = +new Date() - window.animationWaitedStart;
  // Every single app calls goes throught that bootstrap
  render(<Bootstrap />, document.getElementById('app'));
};

const renderAppWait = () => {
  if (window.animationReady !== 'ready') {
    setTimeout(renderAppWait, 100);
  } else {
    renderApp();
  }
};

renderAppWait();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
