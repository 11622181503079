import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import config from '../../config/config.yaml';
import graphql from '../../config/graphql.yaml';
import { submitData } from '../../redux/root/actionCreators';

import RegistrationNok from '../Form/RegistrationNOk';

class Decline extends React.PureComponent {
  static propTypes = {
    profile: PropTypes.shape().isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      ready: false,
    };
  }

  componentDidMount() {
    this.checkUid();
  }

  componentDidUpdate() {
    this.checkUid();
  }

  handleSubmit(rawValues) {
    const { profile, dispatch } = this.props;
    const { uid, firstName, lastName } = profile;
    const { resaKey } = config;
    const values = {
      uid,
      firstName, // firstName / lastName used by
      lastName,
      presence: 0,
    };
    const then = {
      // then is used by axa only for now
      /*
      sms: [
        {
          resaKey,
          template: 'notification',
          dest: [{ uid: values.uid }],
        },
      ],
      */
    };
    const query = graphql.mutation;
    const payload = {
      body: { query, variables: values, resaKey, then},
    };
    // TODO - move mapValuesToQuery / handleSubmit to root/actionCreators ??
    return dispatch(submitData('inscriptionForm', payload, rawValues));
  }

  checkUid() {
    const { profile: { uid } = {} } = this.props;
    const { ready } = this.state;
    if (!ready && uid && !this.submitting) {
      this.submitting = true;
      this.handleSubmit().then(() => {
        this.setState({
          ready: true,
        });
        this.submitting = false;
      });
    }
  }

  render() {
    const { ready } = this.state;
    if (!ready) return null;
    return (
      <div className="tab">
        <Card className="ui-card">
          <CardContent>
            <RegistrationNok />
          </CardContent>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ profile }) => ({
  profile,
});

export default connect(mapStateToProps)(Decline);
