import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Markdown from '../../lib/SimpleContent/Markdown';

const Closed = () => (
  <div className="tab">
    <Card>
      <CardContent>
        <Markdown mdKey="closed" attr="htmlAst" />
      </CardContent>
    </Card>
  </div>
);
export default Closed;
