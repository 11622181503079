import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import SummonField from './SummonField';

import { toInt } from '../normalizers';
import { requiredToEqualValidator } from '../validators';

const styles = theme => ({
  labelFullWidth: {
    justifyContent: 'space-between',
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
});

const ToggleField = props => {
  const { name, disabled, field, classes } = props;
  const { label, required, isInt, ...input } = field;

  const validatorsArray = [];
  if (required) validatorsArray.push(requiredToEqualValidator(1));

  const isIntRefined = isInt || required;

  return (
    <SummonField
      key={`summonField-${name}`}
      reduxFormField={{
        name,
        validate: [...validatorsArray],
        parse: isIntRefined ? toInt : toString,
        format: v => (isIntRefined ? !!v : `${v}`),
      }}
      component="toggle"
      componentProps={{
        label: label && (
          <span>
            {label}
            {required && (
              <span style={{ color: 'red', opacity: '0.87' }}>&nbsp;*</span>
            )}
          </span>
        ),
        disabled,
        classes: { labelPlacementStart: classes.labelFullWidth },
        ...input,
      }}
    />
  );
};

ToggleField.prototype.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  field: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }).isRequired,
  classes: PropTypes.object.isRequired,
};

ToggleField.prototype.defaultProps = {
  disabled: false,
};

export default withStyles(styles)(ToggleField);
