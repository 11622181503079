import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import AppHeader from './Skeleton/AppHeader';
import AppMenu from './Skeleton/AppMenu';
import FormState from '../lib/SummonForm/FormState';

import { getTabItems } from '../selectors/menu';

import CUSTOMIZATION from '../config/customization.yaml';

const {
  header: { sticky },
  tabs,
} = CUSTOMIZATION;

const styles = () => ({
  tabsRoot: {
    minHeight: 0,
  },
  tabsContainer: {
    height: '100%',
  },
  tabRoot: {
    height: 'auto',
    minHeight: 0,
    ...tabs.buttons.style,
  },
  tabRootSelected: tabs.buttons.activeStyle,
  tabLabelContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    lineHeight: 1,
    backgroundColor: 'transparent',
  },
});

class AppSkeleton extends React.Component {
  static contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.shape({ push: PropTypes.func }),
    }),
  };

  static propTypes = {
    children: PropTypes.node.isRequired,
    // MUI Styles
    classes: PropTypes.object.isRequired,
    // REDUX
    landingActive: PropTypes.bool.isRequired,
    landingActiveTab: PropTypes.number.isRequired,
    tabItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  /**
   * On tab or menu click, just redirect to the right URL,
   * change will be handled in Landing.js
   * @param  {integer} value Tab value
   * @author Sylvain Pont
   */
  handleTabChange = (event, value) => {
    const { tabItems, landingActiveTab, landingActive } = this.props;
    const { router } = this.context;
    if (landingActiveTab !== value || !landingActive) {
      router.history.push(tabItems[value].href);
    }
  };

  render() {
    const {
      classes,
      landingActive,
      landingActiveTab,
      tabItems,
      children,
    } = this.props;

    const tabsStyle =
      tabItems.length === 1 ? { ...tabs.style, display: 'none' } : tabs.style;

    const { style: { height = '64px' } = {} } = sticky;

    return (
      <div>
        <AppHeader />
        <div
          style={{
            ...tabs.wrapperStyle,
            position: 'sticky',
            top: height,
            zIndex: 2,
          }}
        >
          <Tabs
            value={landingActive ? landingActiveTab : false}
            onChange={this.handleTabChange}
            className="tab-buttons hide-on-phone"
            style={tabsStyle}
            classes={{
              root: classes.tabsRoot,
              flexContainer: classes.tabsContainer,
            }}
            centered
            variant="fullWidth"
          >
            {_.map(tabItems, (item, index) => (
              <Tab
                key={`${_.camelCase(item.label)}`}
                label={item.label}
                value={index}
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabRootSelected,
                  labelContainer: classes.tabLabelContainer,
                }}
              />
            ))}
          </Tabs>
        </div>
        <AppMenu handleTabChange={this.handleTabChange} />
        <FormState formName="inscriptionForm" />
        <div>{children}</div>
      </div>
    );
  }
}

const mapStateToProps = ({ root, profile }) => ({
  landingActive: root.landingActive,
  landingActiveTab: root.landingActiveTab,
  tabItems: getTabItems({ root, profile }),
});

export default _.flow(
  withStyles(styles),
  connect(mapStateToProps),
)(AppSkeleton);
