import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import VideogPlayer from 'videog-player';

import Markdown from '../../lib/SimpleContent/Markdown';

const videoTheme = {
  palette: {
    background: 'rgb(0, 0, 0)',
    // primary: 'rgb(203, 0, 68)',
    controls: {
      background: 'rgb(245, 245, 245)',
      contrastText: 'rgb(62, 61, 64)',
    },
    progress: {
      primary: 'rgb(203, 0, 68)',
      background: 'rgb(224, 225, 221)',
      buffer: 'rgb(100, 99, 102)',
      over: 'rgb(100, 99, 102)',
    },
    tooltip: {
      background: 'rgb(100, 99, 102)',
      contrastText: 'rgb(250, 250, 250)',
    },
  },
};

const VideoPage = () => (
  <div>
    <Card>
      <CardContent>
        <h3>Best of</h3>
        <VideogPlayer
          id="ce11fb56d43ae5c4f5466caaba9cf11e12d3d8cc"
          poster={3}
          autoplay={false}
          autoplayOnLandscape={false} /* not when multiple videos */
          theme={videoTheme}
        />
      </CardContent>
    </Card>
    <Card>
      <CardContent>
        <Markdown mdKey="video" />
      </CardContent>
    </Card>
  </div>
);

export default VideoPage;
